import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormControl,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { PrismService } from "src/app/shared/services/prism.service";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { TagsService } from "src/app/shared/services/tag.service";
import { BreadcrumbService } from "src/app/shared/services/breadcrumb.service";
@Component({
  selector: "app-create-application",
  providers: [DatePipe],
  templateUrl: "./create-application.component.html",
  styleUrls: ["./create-application.component.scss"],
})
export class CreateApplicationComponent implements OnInit {
  applicationForm: FormGroup | any;
  appId: any;
  appTags: any = [];
  type: string = "Creation";
  ownersList: any = [];
  appTypeList: any = [];
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private PrismService: PrismService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private datepipe: DatePipe,
    private tagService: TagsService,
    private breadCrumService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.applicationForm = this.fb.group({
      id: [""],
      name: ["", Validators.required],
      sys_code: [""],
      description: ["", Validators.required],
      provider: [""],
      version: [""],
      license_type: [""],
      license_validity: [""],
      support_group: [""],
      primary_sme: [""],
      primary_esa: [""],
      it_manager: [""],
      it_director: [""],
      it_slt_member: [""],
      physical_location: [""],
      business_area: [""],
      environment_type: [""],
      comments: [""],
      tags: [""],
      primary_business_owner: [""],
      secondary_business_owner: [""],
      application_type: [""],
    });

    this.route.paramMap.subscribe((res: any) => {
      this.appId = atob(res.get("id"));
      if (this.appId != "new") {
        this.type = "Update";
        this.getApplication();
      }
    });
    let breadCrumb = [
      { label: "Masters", url: "/prism/masters" },
      { label: "Application Master", url: "/application-master" },
      { label: this.type, url: "" },
    ];
    this.breadCrumService.setBreadcrumb(breadCrumb);
    this.getOptionsList();
  }

  getOwnersList() {
    this.PrismService.getUser().subscribe((resp: any) => {
      this.ownersList = resp;
    });
  }

  getAppTypeList() {
    this.PrismService.getApplicationTypes().subscribe((resp: any) => {
      this.appTypeList = resp;
    });
  }

  getOptionsList() {
    this.getOwnersList();
    this.getAppTypeList();
  }

  getApplication() {
    this.spinner.show();
    this.PrismService.getApplicationById(this.appId).subscribe((res: any) => {
      this.spinner.hide();
      this.applicationForm.patchValue(res);
    });
  }

  submitApplication() {
    this.applicationForm.markAllAsTouched();
    if (this.applicationForm.Invalid) {
      return;
    }
    this.spinner.show();
    this.applicationForm.value.license_validity = this.datepipe.transform(
      this.applicationForm.get("license_validity").value,
      "yyyy-MM-dd"
    );
    if (this.appId != "new") {
      this.PrismService.updateApplication(
        this.applicationForm.value,
        this.appId
      ).subscribe((res: any) => {
        this.spinner.hide();
        this.toastrService.success("Application Updated Successfully.");
        this.addTag(res.id);
        this.router.navigate(["application-master"]);
      });
    } else {
      this.PrismService.addApplication(this.applicationForm.value).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.toastrService.success("Application Created Successfully.");
          this.addTag(res.id);
          this.router.navigate(["application-master"]);
        }
      );
    }
  }

  async addTag(id: any) {
    if (this.appTags?.length > 0) {
      await this.appTags.forEach(async (element: any) => {
        let pst = {
          entity_id: id,
          entity_type: "app_master",
          tag_id: element.id,
        };
        await this.tagService.magTag(pst).subscribe((res: any) => {});
      });
    }
  }

  getTagObject() {
    return {
      entity_id: this.appId,
      entity_type: "app_master",
    };
  }
  getChildTags(data: any) {
    this.appTags = data;
  }
}
