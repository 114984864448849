import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {} from "src/app/shared/services/vendor-data.service";
import { PrismService } from "src/app/shared/services/prism.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { BreadcrumbService } from "src/app/shared/services/breadcrumb.service";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class ListComponent implements OnInit {
  headerList: any = [
    "#",
    "Name",
    "Sys Code",
    "Business Area",
    "Primary Business Owner",
    "Secondary business owner",
    "Physical Location",
    "Primary Application Owner",
    "Secondary Application Owner",
    "Action",
  ];
  appMasterList: any = [];
  sortRecords: any = 20;
  CurrentPage: any = 0;
  EndValue: any = 20;
  startValue: any = 0;
  firstTimeLoad: any = true;
  appCount: number;
  breadcrumb: any;
  appTypeList: any = [];
  filterName: any = null;
  filterSysCode: any = null;
  filterBusinessArea: any = null;
  filterBusinessOwner: any = null;
  filterLocation: any = null;
  filterESA: any = null;
  filterSME: any = null;
  filterAppType: any = null;
  filterTag:any = null;
  tagList:any;
  constructor(
    private router: Router,
    private PrismService: PrismService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private breadCrumService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.getApplicationList();
    this.getApplicationTypesList();
    let breadCrumb = [
      { label: "Masters", url: "/prism/masters" },
      { label: "Application Master", url: "/application-master" },
    ];
    this.breadCrumService.setBreadcrumb(breadCrumb);
    this.getTag();
  }

  getApplicationTypesList() {
    this.PrismService.getApplicationTypes().subscribe((resp: any) => {
      this.appTypeList = resp;
    });
  }

  navigateById(id: any) {
    this.router.navigate(["application-master/create-application/" + btoa(id)]);
  }

  getApplicationList() {
    this.spinner.show();
    this.PrismService.getApplicationMaster(
      this.startValue,
      this.EndValue
    ).subscribe((res: any) => {
      this.spinner.hide();
      this.appMasterList = res.records;
      this.appCount = res.total_count;
    });
  }

  getRecords(isFromAttrition?: boolean) {
    if (this.CurrentPage > 0) {
      this.startValue =
        (parseInt(this.CurrentPage) - 1) * parseInt(this.sortRecords);
    } else {
      this.startValue = 0;
    }
    this.EndValue = parseInt(this.startValue) + parseInt(this.sortRecords);
    // if(!this.firstTimeLoad){
    this.getApplicationList();
    // }
    this.firstTimeLoad = false;
  }

  editAppMaster(rec: any, model: any) {}
  deleteAppMaster(id: any, action: any) {
    this.spinner.show();
    this.PrismService.deleteApplication(id).subscribe((res: any) => {
      this.spinner.hide();
      this.toastrService.success("Application Deleted Successfully.");
      this.getApplicationList();
    });
  }

  search() {
    this.PrismService.getFilteredApplicationMaster(
      this.filterName,
      this.filterSysCode,
      this.filterBusinessArea,
      this.filterBusinessOwner,
      this.filterLocation,
      this.filterESA,
      this.filterSME,
      this.filterAppType,
      this.filterTag,
      this.startValue,
      this.EndValue
    ).subscribe((res: any) => {
      this.appMasterList = res.records;
      this.appCount = res.total_count;
    });
  }

  async getTag() {
    await this.PrismService.getTag().subscribe((res: any) => {
      res.forEach((element: any) => {
        element.name = element.tag_name;
      });
      this.tagList = res;
    });
  }
}
