<div
  class="d-flex mb-2"
  style="align-items: center; justify-content: space-between"
>
  <h5>Application Master</h5>
  <div class="justify-content-end">
    <button
      mat-button
      class="bg-primary text-white"
      mat-button
      mat-flat-button
      (click)="navigateById('new')"
    >
      <mat-icon
        aria-hidden="false"
        aria-label="material-icons-filled "
        class="cursor-pointer fs-5"
        >add
      </mat-icon>
      New Application
    </button>
  </div>
</div>
<div class="row">
  <div class="filter-container mb-2">
    <div class="filter-icon" style="margin-top: -20px;">
      <img height="25px" width="25px" src="./assets/images/filter-cp.png" />
    </div>
    <div  class="filter-container" style="overflow-x: auto;height: 60px;">
    <input
      type="text"
      placeholder="Search Name..."
      class="form-control text-input"
      [(ngModel)]="filterName"
      (ngModelChange)="search()"
      style="
        border-radius: 8px;
        font-size: 12px;
        height: 40px;
        min-width: 13rem;
      "
    />
    <input
      type="text"
      placeholder="Search Sys Code..."
      class="form-control text-input"
      [(ngModel)]="filterSysCode"
      (ngModelChange)="search()"
      style="
        border-radius: 8px;
        font-size: 12px;
        height: 40px;
        min-width: 13rem;
      "
    />
    <input
      type="text"
      placeholder="Search Business Area..."
      class="form-control text-input"
      [(ngModel)]="filterBusinessArea"
      (ngModelChange)="search()"
      style="
        border-radius: 8px;
        font-size: 12px;
        height: 40px;
        min-width: 13rem;
      "
    />
    <input
      type="text"
      placeholder="Search Business Owner..."
      class="form-control text-input"
      [(ngModel)]="filterBusinessOwner"
      (ngModelChange)="search()"
      style="
        border-radius: 8px;
        font-size: 12px;
        height: 40px;
        min-width: 13rem;
      "
    />
    <mat-form-field
      class="w-100 filter-dropdown"
      appearance="outline"
      style="font-size: 12px"
    >
      <mat-select
        [(ngModel)]="filterAppType"
        (ngModelChange)="search()"
        placeholder="Search Application Type..."
      >
        <mat-option value=""></mat-option>
        <mat-option *ngFor="let type of appTypeList" value="{{ type.id }}">{{
          type.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <input
      type="text"
      placeholder="Search Location..."
      class="form-control text-input"
      [(ngModel)]="filterLocation"
      (ngModelChange)="search()"
      style="
        border-radius: 8px;
        font-size: 12px;
        height: 40px;
        min-width: 13rem;
      "
    />
    <input
      type="text"
      placeholder="Search Primary Owner..."
      class="form-control text-input"
      [(ngModel)]="filterESA"
      (ngModelChange)="search()"
      style="
        border-radius: 8px;
        font-size: 12px;
        height: 40px;
        min-width: 13rem;
      "
    />
    <input
      type="text"
      placeholder="Search Secondary Owner..."
      class="form-control text-input"
      [(ngModel)]="filterSME"
      (ngModelChange)="search()"
      style="
        border-radius: 8px;
        font-size: 12px;
        height: 40px;
        min-width: 13rem;
      "
    />
    <mat-form-field
      class="w-100 filter-dropdown"
      appearance="outline"
      style="font-size: 12px"
    >
      <mat-select
        [(ngModel)]="filterTag"
        (ngModelChange)="search()"
        placeholder="Search Tag..."
      >
        <mat-option value=""></mat-option>
        <mat-option *ngFor="let type of tagList" value="{{ type.id }}">
            <b>{{ type.tag_name }}</b> - {{ type.tag_category }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    </div>
  </div>
  <div class="col-md-12">
    <div class="custom-height">
      <div
        class="custom-table table-responsive"
        style="max-height: calc(100vh - 220px)"
      >
        <table class="table table-bordered">
          <thead style="position: sticky; z-index: 1">
            <tr>
              <th
                *ngFor="let field of headerList"
                scope="col"
                class="text-center"
              >
                {{ field }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-break"
              *ngFor="let rec of appMasterList; let i = index"
            >
              <td class="text-center">{{ startValue + i + 1 }}</td>
              <td>{{ rec.name }}</td>
              <td>{{ rec.sys_code }}</td>
              <td>{{ rec.business_area }}</td>
              <td>{{ rec.primary_business_owner }}</td>
              <td>{{ rec.secondary_business_owner }}</td>
              <td>{{ rec.physical_location }}</td>
              <td class="text-break">{{ rec.primary_esa }}</td>
              <td class="text-break">{{ rec.primary_sme }}</td>
              <td class="text-center pt-1 pb-1">
                <div class="d-flex justify-content-between">
                  <mat-icon
                    aria-hidden="false"
                    class="cursor-pointer mr-3 fs-2"
                    (click)="navigateById(rec.id)"
                    style="width: 16px"
                  >
                  edit_note
                  </mat-icon>
                  <img
                    (click)="deleteAppMaster(rec?.id, delete)"
                    class="cursor-pointer"
                    src="./assets/images/comment-delete.svg"
                    style="width: 16px"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between">
      <div class="p-1">
        <div
          class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark"
        >
          <span class="p-1">Displaying</span>
          <select
            class="form-select form-select-sm table-sort-filter"
            aria-label="Default select example"
            [(ngModel)]="sortRecords"
            (ngModelChange)="getRecords()"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="150">150</option>
            <option value="200">200</option>
          </select>
          <span class="p-1">{{ appCount }}</span>
          <span class="p-1">records</span>
        </div>
      </div>
      <div class="p-1">
        <ngb-pagination
          [pageSize]="sortRecords"
          [collectionSize]="appCount"
          [maxSize]="2"
          [rotate]="true"
          [(page)]="CurrentPage"
          [boundaryLinks]="true"
          (pageChange)="getRecords()"
        >
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
