<div class="row">
  <div class="col-sm-6 trn-trc">
    <h5>Application {{ type }}</h5>
  </div>
</div>
<form [formGroup]="applicationForm">
  <div class="row">
    <div class="col-sm-12">
      <div *ngIf="applicationForm?.value.id" class="mt-3">
        <app-tag
          [dataObj]="getTagObject(applicationForm?.value?.id)"
          [tagsIds]="applicationForm?.value?.tags"
        ></app-tag>
      </div>
      <app-tag
        [noIds]="true"
        *ngIf="!applicationForm?.value?.id"
        [tagsIds]="applicationForm?.value?.tags"
        (childTags)="getChildTags($event)"
      ></app-tag>

      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Sys Code</mat-label>
        <input matInput formControlName="sys_code" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Description</mat-label>
        <input matInput formControlName="description" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Vendor/Provider</mat-label>
        <input matInput formControlName="provider" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Version</mat-label>
        <input matInput formControlName="version" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>License Type</mat-label>
        <input matInput formControlName="license_type" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>License Validity</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          readonly
          fullWidth
          status="basic"
          (click)="picker.open()"
          formControlName="license_validity"
        />
        <mat-datepicker-toggle matSuffix [for]="picker">
        </mat-datepicker-toggle>
        <mat-datepicker #picker> </mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Support group</mat-label>
        <input matInput formControlName="support_group" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>IT Manager</mat-label>
        <input matInput formControlName="it_manager" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>IT Director</mat-label>
        <input matInput formControlName="it_director" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>IT SLT Member</mat-label>
        <input matInput formControlName="it_slt_member" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Physical Location (Prod)</mat-label>
        <input matInput formControlName="physical_location" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Business Area</mat-label>
        <input matInput formControlName="business_area" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Environment Type</mat-label>
        <input matInput formControlName="environment_type" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Notes/comments</mat-label>
        <input matInput formControlName="comments" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Primary Application Owner </mat-label>
        <input matInput formControlName="primary_sme" />
        <!-- <mat-select
          appearance="filled"
          class="w-100"
          formControlName="primary_sme"
        >
          <mat-option *ngFor="let user of ownersList" [value]="user.id">{{
            user.first_name + " " + user.last_name
          }}</mat-option>
        </mat-select> -->
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Secondary Application Owner </mat-label>
        <input matInput formControlName="primary_esa" />

        <!-- <mat-select
          appearance="filled"
          class="w-100"
          formControlName="primary_esa"
        >
          <mat-option *ngFor="let user of ownersList" [value]="user.id">{{
            user.first_name + " " + user.last_name
          }}</mat-option>
        </mat-select> -->
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Primary Business Owner </mat-label>
        <input matInput formControlName="primary_business_owner" />

        <!-- <mat-select
          appearance="filled"
          class="w-100"
          formControlName="primary_business_owner"
        >
          <mat-option *ngFor="let user of ownersList" [value]="user.id">{{
            user.first_name + " " + user.last_name
          }}</mat-option>
        </mat-select> -->
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Secondary Business Owner </mat-label>
        <input matInput formControlName="secondary_business_owner" />

        <!-- <mat-select
          appearance="filled"
          class="w-100"
          formControlName="secondary_business_owner"
        >
          <mat-option *ngFor="let user of ownersList" [value]="user.id">{{
            user.first_name + " " + user.last_name
          }}</mat-option>
        </mat-select> -->
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Application Type </mat-label>
        <mat-select
          appearance="filled"
          class="w-100"
          formControlName="application_type"
        >
          <mat-option *ngFor="let type of appTypeList" [value]="type.id">{{
            type.display_name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>
<div class="trn-trc-1 mt-2 mb-2">
  <button
    mat-button
    mat-flat-button
    color="primary"
    class="bg-primary text-white float-right"
    (click)="submitApplication()"
    [disabled]="applicationForm.invalid"
    aria-label="add"
  >
    <span *ngIf="type == 'Creation'">Create</span
    ><span *ngIf="type == 'Update'">Update</span>
  </button>
</div>
<br /><br />
