import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-list-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  loading:any = false;
  constructor(
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
     this.messageService.setGnews('IPDB - Application Master')
  }

}
